export default {
	"base.neutral.100": "#0C001F",
	"base.neutral.90": "#180D2A",
	"base.neutral.80": "#231934",
	"base.neutral.60": "#41354f",
	"base.neutral.40": "#7B6A80",
	"base.neutral.20": "#BDB2BD",
	"base.neutral.10": "#E3DEE3",
	"base.neutral.5": "#F3F2F3",
	"base.neutral.0": "#FEFDFF",
	
	"base.primary.10": "{base.pink.10}",
	"base.primary.20": "{base.pink.20}",
	"base.primary.90": "{base.pink.90}",
	"base.primary.100": "{base.pink.100}",
	
	"base.secondary.10": "{base.teal.10}",
	"base.secondary.20": "{base.teal.20}",
	"base.secondary.90": "{base.teal.90}",
	"base.secondary.100": "{base.teal.100}",
	
	"base.tertiary.10": "{base.purple.10}",
	"base.tertiary.20": "{base.purple.20}",
	"base.tertiary.90": "{base.purple.90}",
	"base.tertiary.100": "{base.purple.100}",
	
	"base.red.10": "#ffb8b8",
	"base.red.20": "#ff7a7a",
	"base.red.90": "#ff0a0a",
	"base.red.100": "#cc0000",
	"base.orange.10": "#ffd8b8",
	"base.orange.20": "#ffb67a",
	"base.orange.90": "#ff780a",
	"base.orange.100": "#cc5c00",
	"base.yellow.10": "#fff8b8",
	"base.yellow.20": "#fff27a",
	"base.yellow.90": "#ffe70a",
	"base.yellow.100": "#ccb800",
	"base.lime.10": "#e7ffb8",
	"base.lime.20": "#d3ff7a",
	"base.lime.90": "#adff0a",
	"base.lime.100": "#88cc00",
	"base.green.10": "#b8ffcf",
	"base.green.20": "#7affa7",
	"base.green.90": "#10f95e",
	"base.green.100": "#00cc44",
	"base.teal.10": "#b3feff",
	"base.teal.20": "#75faf7",
	"base.teal.90": "#00ddd6",
	"base.teal.100": "#00aba5",
	"base.blue.10": "#b8e7ff",
	"base.blue.20": "#7ad3ff",
	"base.blue.90": "#0aadff",
	"base.blue.100": "#0088cc",
	"base.purple.10": "#d2b8ff",
	"base.purple.20": "#ab7aff",
	"base.purple.90": "#640aff",
	"base.purple.100": "#4b00cc",
	"base.pink.10": "#ffb8d5",
	"base.pink.20": "#ff7ab2",
	"base.pink.90": "#ff0a70",
	"base.pink.100": "#cc0055"
}
