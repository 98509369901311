export default {
	"application.activityBar.border": "",
	"application.activityBar.activeBorder": "",
	"application.activityBar.activeFocusBorder": "",
	"application.activityBar.activeBackground": "",
	"application.activityBar.background": "{theme.background.tertiary}",
	"application.activityBar.dropBackground": "{theme.background.drop}",
	"application.activityBar.foreground": "{theme.font.link.secondary.active}",
	"application.activityBar.inactiveForeground": "",
	"application.activityBarBadge.background": "{theme.background.badge}",
	"application.activityBarBadge.foreground": "{theme.font.primary}",
	"application.badge.foreground": "{theme.font.primary}",
	"application.badge.background": "{theme.background.badge}",
	"application.focusBorder": "{theme.border.secondary}",
	"application.foreground": "{theme.font.primary}",
	"application.descriptionForeground": "{theme.font.secondary}",
	"application.errorForeground": "{theme.font.danger}",
	"application.icon.foreground": "{theme.font.primary}",
	"application.selection.background": "{theme.background.selection.primary.active}",
	"application.widget.shadow": "#0000",
	"application.window.activeBorder": "#0000",
	"application.window.inactiveBorder": "#0000",
	"application.breadcrumb.background": "{theme.background.primary}",
	"application.breadcrumb.foreground": "{theme.font.secondary}",
	"application.breadcrumb.focusForeground": "{theme.font.link.primary.inactive}",
	"application.breadcrumb.activeSelectionForeground": "{theme.font.link.primary.active}",
	"application.button.foreground": "{theme.font.primary}",
	"application.button.background": "{theme.background.interactive.base}",
	"application.button.hoverBackground": "{theme.background.interactive.hover}",
	"application.checkbox.foreground": "",
	"application.checkbox.background": "",
	"application.checkbox.border": "",
	"application.debugToolBar.background": "{theme.background.quaternary}",
	"application.dropdown.background": "{theme.background.primary}",
	"application.dropdown.foreground": "{theme.font.primary}",
	"application.dropdown.border": "{theme.background.primary}",
	"application.dropdown.listBackground": "",
	"application.editor.background": "{theme.background.primary}",
	"application.editor.foreground": "{theme.font.primary}",
	"application.editor.selectionBackground": "{theme.background.selection.primary.active}",
	"application.editor.selectionHighlightBackground": "{theme.background.selection.secondary.inactive}",
	"application.editor.inactiveSelectionBackground": "{theme.background.selection.primary.inactive}",
	"application.editor.wordHighlightBackground": "{theme.background.highlight.primary.inactive}",
	"application.editor.wordHighlightStrongBackground": "{theme.background.highlight.secondary.inactive}",
	"application.editor.findMatchBackground": "{theme.background.highlight.primary.active}",
	"application.editor.findMatchHighlightBackground": "{theme.background.highlight.primary.inactive}",
	"application.editor.hoverHighlightBackground": "{theme.background.tertiary}",
	"application.editor.lineHighlightBackground": "{theme.background.secondary}",
	"application.editor.rangeHighlightBackground": "{theme.background.tertiary}",
	"application.editorLineNumber.foreground": "{theme.font.tertiary}",
	"application.editorLineNumber.activeForeground": "{theme.font.primary}",
	"application.editorCursor.foreground": "{theme.background.info}",
	"application.editorLink.activeForeground": "{theme.font.link.primary.inactive}",
	"application.editorGutter.background": "{theme.background.primary}",
	"application.editorGutter.modifiedBackground": "{theme.border.info}",
	"application.editorGutter.addedBackground": "{theme.border.success}",
	"application.editorGutter.deletedBackground": "{theme.border.danger}",
	"application.editorOverviewRuler.border": "{theme.border.secondary}",
	"application.editorOverviewRuler.findMatchForeground": "{theme.background.success}",
	"application.editorOverviewRuler.rangeHighlightForeground": "",
	"application.editorOverviewRuler.selectionHighlightForeground": "{theme.background.selection.primary.inactive}",
	"application.editorOverviewRuler.currentContentForeground": "",
	"application.editorOverviewRuler.incomingContentForeground": "",
	"application.editorOverviewRuler.commonContentForeground": "",
	"application.editorOverviewRuler.modifiedForeground": "{theme.border.info}",
	"application.editorOverviewRuler.addedForeground": "{theme.border.success}",
	"application.editorOverviewRuler.deletedForeground": "{theme.border.danger}",
	"application.editorOverviewRuler.errorForeground": "{theme.border.danger}",
	"application.editorOverviewRuler.warningForeground": "{theme.border.warning}",
	"application.editorOverviewRuler.infoForeground": "{theme.border.info}",
	"application.editorOverviewRuler.bracketMatchForeground": "{application.editorBracketMatch.background}",
	"application.editorError.foreground": "{theme.font.danger}",
	"application.editorWarning.foreground": "{theme.font.warning}",
	"application.editorInfo.foreground": "{theme.font.info}",
	"application.editorHint.foreground": "{theme.font.active}",
	"application.editorBracketMatch.background": "{theme.background.highlight.secondary.inactive}",
	"application.editorBracketMatch.border": "#0000",
	"application.editorWhitespace.foreground": "{theme.font.ghost}",
	"application.editorIndentGuide.background": "{theme.border.tertiary}",
	"application.editorIndentGuide.activeBackground": "{theme.border.primary}",
	"application.editorRuler.foreground": "{theme.border.secondary}",
	"application.editorCodeLens.foreground": "{theme.font.link.secondary.inactive}",
	"application.editorUnnecessaryCode.opacity": "#00000088",
	"application.editorUnnecessaryCode.border": "{theme.border.primary}",
	"application.editorGroup.emptyBackground": "{theme.background.secondary}",
	"application.editorGroup.border": "{theme.border.primary}",
	"application.editorGroup.dropBackground": "{theme.background.drop}",
	"application.editorGroupHeader.noTabsBackground": "{theme.background.primary}",
	"application.editorGroupHeader.tabsBackground": "{theme.background.secondary}",
	"application.editorGroupHeader.tabsBorder": "#0000",
	"application.editorWidget.foreground": "{theme.font.primary}",
	"application.editorWidget.background": "{theme.background.quaternary}",
	"application.editorWidget.border": "{theme.border.secondary}",
	"application.editorWidget.resizeBorder": "{theme.border.primary}",
	"application.editorSuggestWidget.foreground": "{theme.font.secondary}",
	"application.editorSuggestWidget.background": "{theme.background.quaternary}",
	"application.editorSuggestWidget.border": "{theme.border.secondary}",
	"application.editorSuggestWidget.highlightForeground": "{theme.font.primary}",
	"application.editorSuggestWidget.selectedBackground": "{theme.background.selection.tertiary.active}",
	"application.editorHoverWidget.background": "{theme.background.tertiary}",
	"application.editorHoverWidget.border": "{theme.border.tertiary}",
	"application.editorHoverWidget.statusBarBackground": "{theme.background.tertiary}",
	"application.extensionButton.prominentForeground": "{theme.font.primary}",
	"application.extensionButton.prominentBackground": "{theme.background.interactive.base}",
	"application.extensionButton.prominentHoverBackground": "{theme.background.interactive.hover}",
	"application.gitDecoration.addedResourceForeground": "{theme.font.success}",
	"application.gitDecoration.modifiedResourceForeground": "{theme.font.info}",
	"application.gitDecoration.deletedResourceForeground": "{theme.font.danger}",
	"application.gitDecoration.untrackedResourceForeground": "{theme.font.warning}",
	"application.gitDecoration.ignoredResourceForeground": "{theme.font.quaternary}",
	"application.gitDecoration.conflictingResourceForeground": "{theme.font.active}",
	"application.gitDecoration.submoduleResourceForeground": "",
	"application.input.background": "{theme.background.tertiary}",
	"application.input.border": "#0000",
	"application.input.foreground": "{theme.font.primary}",
	"application.input.placeholderForeground": "{theme.font.tertiary}",
	"application.inputValidation.errorBackground": "{theme.background.danger}",
	"application.inputValidation.errorForeground": "{theme.font.inverse}",
	"application.inputValidation.errorBorder": "{theme.border.danger}",
	"application.inputValidation.infoBackground": "{theme.background.info}",
	"application.inputValidation.infoForeground": "{theme.font.inverse}",
	"application.inputValidation.infoBorder": "{theme.border.info}",
	"application.inputValidation.warningBackground": "{theme.background.warning}",
	"application.inputValidation.warningForeground": "{theme.font.inverse}",
	"application.inputValidation.warningBorder": "{theme.border.warning}",
	"application.inputOption.activeBorder": "#0000",
	"application.inputOption.activeBackground": "{theme.background.interactive.base}",
	"application.tree.indentGuidesStroke": "",
	"application.list.inactiveFocusBackground": "",
	"application.list.filterMatchBackground": "",
	"application.list.filterMatchBorder": "",
	"application.list.activeSelectionBackground": "{theme.background.selection.tertiary.active}",
	"application.list.activeSelectionForeground": "{theme.font.primary}",
	"application.list.inactiveSelectionBackground": "{theme.background.selection.tertiary.inactive}",
	"application.list.inactiveSelectionForeground": "{theme.font.primary}",
	"application.list.focusBackground": "{theme.background.selection.tertiary.active}",
	"application.list.focusForeground": "{theme.font.primary}",
	"application.list.hoverBackground": "{theme.background.selection.tertiary.inactive}",
	"application.list.hoverForeground": "{theme.font.primary}",
	"application.list.dropBackground": "{theme.background.selection.secondary.active}",
	"application.list.highlightForeground": "{theme.font.primary}",
	"application.list.invalidItemForeground": "{theme.font.danger}",
	"application.list.errorForeground": "{theme.font.danger}",
	"application.list.warningForeground": "{theme.font.warning}",
	"application.listFilterWidget.background": "{theme.background.primary}",
	"application.listFilterWidget.outline": "{theme.border.secondary}",
	"application.listFilterWidget.noMatchesOutline": "{theme.border.danger}",
	"application.minimap.findMatchHighlight": "{application.editor.findMatchBackground}",
	"application.minimap.selectionHighlight": "{application.editor.selectionBackground}",
	"application.panel.border": "{theme.border.tertiary}",
	"application.panel.background": "{theme.background.tertiary}",
	"application.panel.dropBackground": "{theme.background.drop}",
	"application.panelTitle.activeBorder": "{theme.border.active}",
	"application.panelTitle.activeForeground": "{theme.font.primary}",
	"application.panelTitle.inactiveForeground": "{theme.font.secondary}",
	"application.peekView.border": "{theme.border.primary}",
	"application.peekViewEditor.background": "{theme.background.primary}",
	"application.peekViewEditor.matchHighlightBackground": "{theme.background.quaternary}",
	"application.peekViewResult.background": "{theme.background.secondary}",
	"application.peekViewResult.fileForeground": "{theme.font.link.primary.active}",
	"application.peekViewResult.lineForeground": "{theme.font.primary}",
	"application.peekViewResult.matchHighlightBackground": "{application.editor.findMatchHighlightBackground}",
	"application.peekViewResult.selectionBackground": "{application.editor.findMatchBackground}",
	"application.peekViewTitle.background": "{theme.background.tertiary}",
	"application.peekViewTitleDescription.foreground": "{theme.font.primary}",
	"application.peekViewTitleLabel.foreground": "{theme.font.tertiary}",
	"application.progressBar.background": "{theme.background.info}",
	"application.pickerGroup.foreground": "{theme.font.tertiary}",
	"application.pickerGroup.border": "{theme.border.primary}",
	"application.quickInput.foreground": "{theme.font.primary}",
	"application.quickInput.background": "{theme.background.quaternary}",
	"application.scrollbar.shadow": "",
	"application.scrollbarSlider.activeBackground": "",
	"application.scrollbarSlider.background": "",
	"application.scrollbarSlider.hoverBackground": "",
	"application.settings.headerForeground": "{theme.font.link.secondary.active}",
	"application.settings.textInputBackground": "{theme.background.tertiary}",
	"application.settings.textInputForeground": "{theme.font.primary}",
	"application.settings.dropdownBackground": "{theme.background.tertiary}",
	"application.settings.dropdownForeground": "{theme.font.primary}",
	"application.settings.modifiedItemIndicator": "{theme.border.info}",
	"application.sideBar.border": "",
	"application.sideBar.background": "{theme.background.secondary}",
	"application.sideBar.foreground": "{theme.font.secondary}",
	"application.sideBar.dropBackground": "{theme.background.selection.primary.active}",
	"application.sideBarSectionHeader.background": "{theme.background.secondary}",
	"application.sideBarSectionHeader.foreground": "{theme.font.tertiary}",
	"application.sideBarSectionHeader.border": "{theme.border.tertiary}",
	"application.sideBarTitle.foreground": "{theme.font.primary}",
	"application.statusBar.background": "{theme.background.quaternary}",
	"application.statusBar.foreground": "{theme.font.primary}",
	"application.statusBar.border": "#0000",
	"application.statusBar.debuggingBackground": "{theme.background.debug}",
	"application.statusBar.debuggingForeground": "{theme.font.primary}",
	"application.statusBar.debuggingBorder": "#0000",
	"application.statusBar.noFolderBackground": "{theme.background.primary}",
	"application.statusBar.noFolderForeground": "{theme.font.primary}",
	"application.statusBar.noFolderBorder": "#0000",
	"application.statusBarItem.activeBackground": "{theme.background.selection.secondary.active}",
	"application.statusBarItem.hoverBackground": "{theme.background.selection.secondary.inactive}",
	"application.statusBarItem.prominentBackground": "{theme.background.badge}",
	"application.statusBarItem.prominentForeground": "{theme.font.primary}",
	"application.statusBarItem.remoteBackground": "{theme.background.interactive.base}",
	"application.statusBarItem.remoteForeground": "{theme.font.primary}",
	"application.symbolIcon.arrayForeground": "",
	"application.symbolIcon.booleanForeground": "",
	"application.symbolIcon.classForeground": "",
	"application.symbolIcon.colorForeground": "",
	"application.symbolIcon.constantForeground": "",
	"application.symbolIcon.constructorForeground": "",
	"application.symbolIcon.enumeratorForeground": "",
	"application.symbolIcon.enumeratorMemberForeground": "",
	"application.symbolIcon.eventForeground": "",
	"application.symbolIcon.fieldForeground": "",
	"application.symbolIcon.fileForeground": "",
	"application.symbolIcon.folderForeground": "",
	"application.symbolIcon.functionForeground": "",
	"application.symbolIcon.interfaceForeground": "",
	"application.symbolIcon.keyForeground": "",
	"application.symbolIcon.keywordForeground": "",
	"application.symbolIcon.methodForeground": "",
	"application.symbolIcon.moduleForeground": "",
	"application.symbolIcon.namespaceForeground": "",
	"application.symbolIcon.nullForeground": "",
	"application.symbolIcon.numberForeground": "",
	"application.symbolIcon.objectForeground": "",
	"application.symbolIcon.operatorForeground": "",
	"application.symbolIcon.packageForeground": "",
	"application.symbolIcon.propertyForeground": "",
	"application.symbolIcon.referenceForeground": "",
	"application.symbolIcon.snippetForeground": "",
	"application.symbolIcon.stringForeground": "",
	"application.symbolIcon.structForeground": "",
	"application.symbolIcon.textForeground": "",
	"application.symbolIcon.typeParameterForeground": "",
	"application.symbolIcon.unitForeground": "",
	"application.symbolIcon.variableForeground": "",
	"application.tab.border": "#0000",
	"application.tab.activeBackground": "{theme.background.primary}",
	"application.tab.activeForeground": "{theme.font.primary}",
	"application.tab.inactiveBackground": "{theme.background.tertiary}",
	"application.tab.inactiveForeground": "{theme.font.tertiary}",
	"application.tab.unfocusedHoverBorder": "{theme.border.active}",
	"application.tab.unfocusedActiveForeground": "{theme.font.secondary}",
	"application.tab.unfocusedInactiveForeground": "{theme.font.tertiary}",
	"application.textBlockQuote.background": "{theme.background.tertiary}",
	"application.textBlockQuote.border": "{theme.border.active}",
	"application.textCodeBlock.background": "{theme.background.secondary}",
	"application.textLink.activeForeground": "{theme.font.link.primary.active}",
	"application.textLink.foreground": "{theme.font.link.primary.inactive}",
	"application.textPreformat.foreground": "{theme.font.info}",
	"application.textSeparator.foreground": "",
	"application.titleBar.border": "#0000",
	"application.titleBar.activeBackground": "{theme.background.quaternary}",
	"application.titleBar.activeForeground": "{theme.font.primary}",
	"application.titleBar.inactiveBackground": "{theme.background.secondary}",
	"application.titleBar.inactiveForeground": "{theme.font.secondary}",
	
	"application.terminal.ansiWhite": "{theme.font.primary}",
	"application.terminal.ansiBlack": "{theme.font.inverse}",
	"application.terminal.ansiBlue": "{theme.font.code.7}",
	"application.terminal.ansiCyan": "{theme.font.code.6}",
	"application.terminal.ansiGreen": "{theme.font.code.5}",
	"application.terminal.ansiMagenta": "{theme.font.code.9}",
	"application.terminal.ansiRed": "{theme.font.code.1}",
	"application.terminal.ansiYellow": "{theme.font.code.3}",
	"application.terminal.ansiBrightWhite": "{theme.font.secondary}",
	"application.terminal.ansiBrightBlack": "{theme.font.inverse}",
	"application.terminal.ansiBrightBlue": "{theme.font.code.27}",
	"application.terminal.ansiBrightCyan": "{theme.font.code.26}",
	"application.terminal.ansiBrightGreen": "{theme.font.code.25}",
	"application.terminal.ansiBrightMagenta": "{theme.font.code.29}",
	"application.terminal.ansiBrightRed": "{theme.font.code.21}",
	"application.terminal.ansiBrightYellow": "{theme.font.code.23}",
	"application.terminal.selectionBackground": "{theme.background.selection.secondary.active}",
	"application.terminalCursor.background": "{theme.background.selection.primary.active}",
	"application.terminalCursor.foreground": "{theme.background.selection.primary.active}",
	
	"application.minimap.errorHighlight": "",
	"application.minimap.warningHighlight": "",
	"application.minimap.background": "",
	"application.minimapSlider.background": "",
	"application.minimapSlider.hoverBackground": "",
	"application.minimapSlider.activeBackground": "",
	"application.minimapGutter.addedBackground": "",
	"application.minimapGutter.modifiedBackground": "",
	"application.minimapGutter.deletedBackground": "",
	"application.editorGroup.focusedEmptyBorder": "",
	"application.tab.unfocusedActiveBackground": "",
	"application.tab.activeBorder": "",
	"application.tab.unfocusedActiveBorder": "",
	"application.tab.activeBorderTop": "",
	"application.tab.unfocusedActiveBorderTop": "",
	"application.tab.hoverBackground": "",
	"application.tab.unfocusedHoverBackground": "",
	"application.tab.hoverBorder": "",
	"application.tab.activeModifiedBorder": "",
	"application.tab.inactiveModifiedBorder": "",
	"application.tab.unfocusedActiveModifiedBorder": "",
	"application.tab.unfocusedInactiveModifiedBorder": "",
	"application.editorPane.background": "",
	"application.editorCursor.background": "",
	"application.editor.selectionForeground": "",
	"application.editor.selectionHighlightBorder": "",
	"application.editor.wordHighlightBorder": "",
	"application.editor.wordHighlightStrongBorder": "",
	"application.editor.findRangeHighlightBackground": "",
	"application.editor.findMatchBorder": "",
	"application.editor.findMatchHighlightBorder": "",
	"application.editor.findRangeHighlightBorder": "",
	"application.searchEditor.findMatchBackground": "",
	"application.searchEditor.findMatchBorder": "",
	"application.searchEditor.textInputBorder": "",
	"application.editor.lineHighlightBorder": "",
	"application.editor.rangeHighlightBorder": "",
	"application.editor.symbolHighlightBackground": "",
	"application.editor.symbolHighlightBorder": "",
	"application.editorLightBulb.foreground": "",
	"application.editorLightBulbAutoFix.foreground": "",
	"application.editor.foldBackground": "",
	
	
	"application.editorOverviewRuler.wordHighlightForeground": "",
	"application.editorOverviewRuler.wordHighlightStrongForeground": "",
	"application.editorError.border": "",
	"application.editorWarning.border": "",
	"application.editorInfo.border": "",
	"application.editorHint.border": "",
	"application.problemsErrorIcon.foreground": "",
	"application.problemsWarningIcon.foreground": "",
	"application.problemsInfoIcon.foreground": "",
	"application.editorGutter.commentRangeForeground": "",
	"application.diffEditor.insertedTextBackground": "",
	"application.diffEditor.insertedTextBorder": "",
	"application.diffEditor.removedTextBackground": "",
	"application.diffEditor.removedTextBorder": "",
	"application.diffEditor.border": "",
	"application.editorHoverWidget.foreground": "",
	"application.debugExceptionWidget.background": "",
	"application.debugExceptionWidget.border": "",
	"application.editorMarkerNavigation.background": "",
	"application.editorMarkerNavigationError.background": "",
	"application.editorMarkerNavigationWarning.background": "",
	"application.editorMarkerNavigationInfo.background": "",
	"application.peekViewEditorGutter.background": "",
	"application.peekViewEditor.matchHighlightBorder": "",
	"application.peekViewResult.selectionForeground": "",
	"application.merge.currentHeaderBackground": "",
	"application.merge.currentContentBackground": "",
	"application.merge.incomingHeaderBackground": "",
	"application.merge.incomingContentBackground": "",
	"application.merge.border": "",
	"application.merge.commonContentBackground": "",
	"application.merge.commonHeaderBackground": "",
	"application.panelInput.border": "",
}
