/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "theme-prod",
            "region": "us-west-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "theme",
            "endpoint": "https://5ep2pw9yla.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:626ece01-1197-4c74-90fc-c7180841b298",
    "aws_cognito_region": "us-west-2",
    "oauth": {},
    "aws_mobile_analytics_app_id": "511c54634e334b1fb47147453b2e8ef3",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
