const pre = `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple Computer//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
<!-- Generated by: Themeweaver                         -->
<!-- ============================================      -->
<!-- app:  http://themeweaver.dev                      -->
<!-- code: https://github.com/dbanksdesign/themeweaver -->
<plist version="1.0">\n`;

const post = `\n</plist>`;

const generatePlistRecursive = (obj, indent = `\t`) => {
	const newIndent = indent + `\t`;
	
	switch (Object.prototype.toString.call(obj)) {
		case '[object Array]':
			return `${indent}<array>\n` +
					obj.map(val => generatePlistRecursive(val, newIndent))
						.join('\n') +
					`${indent}</array>`;
		case '[object Object]':
			return `${indent}<dict>\n` +
					Object.keys(obj).map(key => {
					const val = obj[key];
					return `${newIndent}<key>${key}</key>\n${generatePlistRecursive(val, newIndent)}`;
				}).join('\n') +
				`\n${indent}</dict>`;
		default:
			return `${indent}<string>${obj}</string>`;
	}
}

const generatePlist = (obj) => {
	return pre + generatePlistRecursive(obj) + post;
}

export default generatePlist;
