import React from 'react';

const Logo2 = () => (
	<svg className="tw-logo-svg" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path className="hatch-1" d="M20 20L60 60V180H20V20Z" fill="#DA4281"/>
		<path className="hatch-2" d="M180 20L140 60V180H180V20Z" fill="#FC63A2"/>
		<path className="hatch-3" d="M80 100H120L60 180H20L80 100Z" fill="#7D43E1"/>
		<path className="hatch-4" d="M120 100H80L140 180H180L120 100Z" fill="#5F27C1"/>
	</svg>
)

export const LogoWithText = () => (
	<svg className="tw-logo-svg" viewBox="0 0 1418 200" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path className="hatch-1" d="M20 20L60 60V180H20V20Z"/>
		<path className="hatch-2" d="M180 20L140 60V180H180V20Z"/>
		<path className="hatch-3" d="M80 100H120L60 180H20L80 100Z"/>
		<path className="hatch-4" d="M120 100H80L140 180H180L120 100Z"/>
		<path className="tw-logo-letter" d="M277.88 65.5V153H266.12V65.5H233.5V55.28H310.5V65.5H277.88Z" fill="black"/>
		<path className="tw-logo-letter" d="M395.985 108.62H357.905V153H346.145V55.28H357.905V98.4H395.985V55.28H407.745V153H395.985V108.62Z" fill="black"/>
		<path className="tw-logo-letter" d="M452.491 153V55.28H512.691V65.5H464.251V98.4H511.011V108.62H464.251V142.78H512.691V153H452.491Z" fill="black"/>
		<path className="tw-logo-letter" d="M608.536 104V73.06H607.416L586.836 123.32L566.256 73.06H565.136V104V153H554.356V55.28H569.476L586.696 98.4H587.536L604.756 55.28H619.316V153H608.536V104Z" fill="black"/>
		<path className="tw-logo-letter" d="M662.381 153V55.28H722.581V65.5H674.141V98.4H720.901V108.62H674.141V142.78H722.581V153H662.381Z" fill="black"/>
		<path className="tw-logo-letter" d="M767.327 153L759.627 55.28H770.127L774.187 111.28L776.287 140.82H777.407L790.287 80.76H803.447L816.327 140.82H817.447L819.547 111.28L823.607 55.28H833.827L826.127 153H809.187L797.287 94.62H796.167L784.267 153H767.327Z"/>
		<path className="tw-logo-letter" d="M872.272 153V55.28H932.472V65.5H884.032V98.4H930.792V108.62H884.032V142.78H932.472V153H872.272Z"/>
		<path className="tw-logo-letter" d="M1031.96 153L1023.7 125.14H989.397L981.137 153H968.957L998.917 55.28H1014.46L1044.42 153H1031.96ZM1007.18 67.74H1005.92L992.197 114.92H1020.9L1007.18 67.74Z"/>
		<path className="tw-logo-letter" d="M1103.58 153L1074.6 55.28H1087.06L1100.92 104.14L1111.42 141.1H1112.12L1122.62 104.14L1136.48 55.28H1148.52L1119.54 153H1103.58Z"/>
		<path className="tw-logo-letter" d="M1187.11 153V55.28H1247.31V65.5H1198.87V98.4H1245.63V108.62H1198.87V142.78H1247.31V153H1187.11Z"/>
		<path className="tw-logo-letter" d="M1303.81 153H1292.05V55.28H1327.61C1336.67 55.28 1343.57 57.7533 1348.33 62.7C1353.09 67.6467 1355.47 74.5533 1355.47 83.42C1355.47 91.3533 1353.42 97.7 1349.31 102.46C1345.21 107.22 1339.23 109.973 1331.39 110.72L1356.31 153H1343.15L1319.35 111.28H1303.81V153ZM1327.33 101.34C1332.28 101.34 1336.11 100.127 1338.81 97.7C1341.61 95.18 1343.01 91.6333 1343.01 87.06V79.78C1343.01 75.2067 1341.61 71.7067 1338.81 69.28C1336.11 66.76 1332.28 65.5 1327.33 65.5H1303.81V101.34H1327.33Z"/>
	</svg>
)

const Logo = () => (
	<svg className="tw-logo-svg" viewBox="0 0 228 228" version="1.1" aria-hidden="true">
		<g transform="translate(114.000000, 114.000000) rotate(45.000000) translate(-114.000000, -114.000000) translate(14.000000, 14.000000)">
			<path d="M81,129 L181,129 C188.731986,129 195,135.268014 195,143 C195,150.731986 188.731986,157 181,157 L81,157 L81,157 L81,129 Z" className="hatch-2" fill="#D8D8D8" transform="translate(138.000000, 143.000000) rotate(90.000000) translate(-138.000000, -143.000000) "></path>
			<path d="M138,0 C145.731986,-1.42034288e-15 152,6.2680135 152,14 L152,38 L152,38 L124,38 L124,14 C124,6.2680135 130.268014,1.42034288e-15 138,0 Z" className="hatch-2" fill="#D8D8D8"></path>
			<path d="M86,48 L186,48 C193.731986,48 200,54.2680135 200,62 C200,69.7319865 193.731986,76 186,76 L86,76 L86,76 L86,48 Z" className="hatch-1" fill="#EF5350"></path>
			<path d="M48,162 L76,162 L76,186 C76,193.731986 69.7319865,200 62,200 C54.2680135,200 48,193.731986 48,186 L48,162 L48,162 Z" className="hatch-2" fill="#D8D8D8"></path>
			<path d="M162,124 L186,124 C193.731986,124 200,130.268014 200,138 C200,145.731986 193.731986,152 186,152 L162,152 L162,152 L162,124 Z" className="hatch-1" fill="#EF5350"></path>
			<path d="M14,124 L114,124 L114,124 L114,152 L14,152 C6.2680135,152 9.46895252e-16,145.731986 0,138 C-9.46895252e-16,130.268014 6.2680135,124 14,124 Z" className="hatch-1" fill="#EF5350"></path>
			<path d="M62,0 C69.7319865,-1.42034288e-15 76,6.2680135 76,14 L76,114 L76,114 L48,114 L48,14 C48,6.2680135 54.2680135,1.42034288e-15 62,0 Z" className="hatch-2" fill="#D8D8D8"></path>
			<path d="M14,48 L38,48 L38,48 L38,76 L14,76 C6.2680135,76 9.46895252e-16,69.7319865 0,62 C-9.46895252e-16,54.2680135 6.2680135,48 14,48 Z" className="hatch-1" fill="#EF5350"></path>
		</g>
	</svg>
)

export default Logo2
