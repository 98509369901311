const firstNames = [
	'Cyber',
	'Electric',
	'Flux',
	'Rainbow',
	'Marshmallow',
	'One',
	'Alpha',
	'Omega',
	'Acid',
	'Hyper',
	'Cobalt',
	'Groove',
	'Azure',
	'Pure',
	'Deep',
	'Exalted',
	'Everlasting',
	'Fatal',
	'Familiar',
	'Fearless',
	'Fickle',
	'Flat',
	'Fluid',
	'Fresh',
	'Forsaken',
	'Gaseous',
	'Gentle',
	'Functional',
	'Glorious',
	'Graceful',
	'Gracious',
	'Grimy',
	'Harmonious',
	'Harsh',
	'Heavenly',
	'Hefty',
	'Heartfelt',
	'Haunting',
	'Infinite',
	'Insidious',
	'Jovial',
	'Joyous',
	'Kaleidoscopic',
	'Juicy',
	'Jubilant',
	'Linear',
	'Liquid',
	'Livid',
	'Melodic',
	'Menacing',
	'Mellow',
	'Medium',
	'Modest',
	'Mysterious',
	'Nasty',
	'Naughty',
	'Nautical',
	'Naive',
	'Mundane',
	'Misty',
	'Modern',
	'Lustrous',
	'Mad',
	'Massive',
	'Lost',
	'Loud',
	'Loyal',
	'Lucky',
	'Luminous',
];

const lastNames = [
	'Overdrive',
	'Override',
	'Dreams',
	'Nights',
	'Cat',
	'City',
	'Panic',
	'Nebula',
	'Nova',
	'Source',
	'Space',
	'Zero',
	'Panda',
	'Horizon',
	'Moon',
	'Assumption',
	'Enthusiasm',
	'Perception',
	'Presence',
	'Revolution',
	'Courage',
	'Conclusion',
	'Chocolate',
	'Reaction',
	'Sandwich',
	'Salad',
	'Potato',
	'Consequence',
	'Departure',
	'Celebration',
	'Diamond',
	'Fortune',
	'Midnight',
	'Obligation',
	'Platform',
	'Indication',
	'Intention',
	'Youth',
	'Time',
	'Process',
	'Taste',
	'Summer',
	'Winter',
	'Desire',
	'Plastic',
	'Phase',
	'Sky',
	'Stage',
	'Trip',
	'Gear',
	'Net',
	'Party',
	'Edge',
	'Option',
	'Prototype',
	'Maximum',
	'Influence',
	'Rain',
	'Target',
	'Damage',
	'Taste',
	'Living',
	'Desire',
	
]


const themeNameGenerator = () => {
	return firstNames[Math.round(Math.random() * (firstNames.length-1))] +
		lastNames[Math.round(Math.random() * (lastNames.length-1))];
}

export default themeNameGenerator;
